/* HEADING STYLES */

.top {
  justify-content: center;
  display: flex;
  overflow: hidden;
  background-color: white;
  &__banner,
  &__banner::after,
  &__banner:link {
    background-color: #ededed;
    padding: 0.7vh;
    font-size: 1.2vh;
    text-align: center;
    color: #82818e;
    text-decoration: none;
    font-weight: 600;
  }

  &__title {
    -webkit-text-stroke-width: 0.2vh;
    -webkit-text-stroke-color: #87c2d5;
    color: #e7f6f6;
    font-size: 7vh;
  }

  &__subtitle {
    font-size: 3vh;
    padding: 4vh 0vw;
    font-weight: bold;
  }

  &__intro {
    font-size: 2vh;
    margin-bottom: 4vh;
  }

  &__button-div {
    background-color: #d6eeee;
    border-radius: 50px;
    padding: 1vh 5vw;
    text-align: center;
    transition: 0.4s;
    color: hsl(192, 16%, 56%);
    cursor: pointer;
  }

  &__button-div:hover {
    color: hsl(0, 0%, 100%);
    background-color: #9ed3d3;
  }

  &__hima {
    margin: 0vw;
    border-radius: 70%;
    max-width: 50vw;
    max-height: 50vh;
    width: auto;
    height: auto;

  @include respond(tab-port) {
    display: none;
  }

  @include respond(tab-land) {
    display: none;
  }

  @include respond(phone) {
    display: none;
  }

  @include respond(ipad) {
    display: none;
  }
}
}

table {
  padding: 5vh 5vw;
}

a,
a::before,
a::after,
a:link {
  text-decoration-color: white;
}