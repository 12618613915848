body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

.App {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: #747380;
  text-align: left;
  justify-content: center;
  overflow: hidden;
  margin: 0vh 12vw;
}

a,
a::after,
a:link {
  text-decoration-color: white;
}

.section-heading {
  padding-bottom: 3vh;
  font-size: 4vh;
  color: slategray;
  transition: background-position 0.5s ease-out;
}

.section {
  background: linear-gradient(to bottom, #eafaff, #d9e6fb);
  padding: 10vh 8vw;
  max-width: 100%;
}

.more {
  color: inherit;
  border: #bdb8b8;
  font-size: 2vh;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 50px;
  cursor: pointer;
  user-select: none;
}
