@import "./_mixins";

.extra-curriculars {
  background: linear-gradient(to bottom, #d9e6fb, #fafafa);
  padding: 0vh 8vw;
  max-width: 100%;
}

.ec {
  padding: 3vh 2vw;
  background-color: #fafafa;
  color: #bababa;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 13px;
  width: 100%;
  max-width: 67vw;
  margin-bottom: 5vh;
  margin-left: auto;
  margin-right: auto;
  max-height: 40vh;

  @include respond(tab-port) {
    min-width: 50vw;
    min-height:60vh;
    align-items: center;
  }
  @include respond(tab-land) {
    min-width: 50vw;
    min-height:60vh;
  }
  @include respond(ipad) {
    min-width: 50vw;
    min-height:60vh;
    align-content: center;
  }
  @include respond(phone) {
    min-width: 70vw;
    min-height:70vh;
  }

  &__name {
    background-color: #6e90c7;
    color: white;
    font-weight: normal;
    padding: 0.5vh 0.8vw;
    border-radius: 4px;
    font-size: 2.3vh;
  }

  &__name:link,
  &__name::after {
    text-decoration: underline;
    color: white;
  }

  &__image {
    box-shadow: 0 0 12px 12px white inset;
    max-width: 20vw;
    margin-top: 4vh;
    margin-right: 1.5vw;
    margin-bottom: auto;
    @include respond(tab-port) {
      min-width: 45vw;
      margin: 0vh 2.5vw;
    }
    @include respond(tab-land) {
      min-width: 45vw;
      margin: 0vh 2.5vw;
    }
    @include respond(ipad) {
      min-width: 45vw;
      margin: 0vh 2.5vw;
    }
    @include respond(phone) {
      min-width: 65vw;
      margin: 0vh 2.5vw;
    }
  }

  &__info {
    float: right;
    margin-right: 1vw;
    font-size: 1.5vw;
    max-width: 41vw;
    width: auto;
    margin-bottom: 0.5vh;
    @include respond(tab-port) {
      float: none;
      max-width: 65vw;
      margin-right: 0vw;
      height:fit-content;
    }
    @include respond(tab-land) {
      float: right;
      max-width: 65vw;
      margin-right: 0;
      height: auto;
    }
    @include respond(ipad) {
      float: right;
      max-width: 65vw;
      margin-right: 0;
    }
    @include respond(phone) {
      max-width: 75vw;
      float: none;
    }
  }

  &__description {
    font-weight: bold;
    color: rgb(95, 95, 95);
    font-size: 1.6vh;
    margin-top: 0.6vh;
  }

  &__heading {
    font-weight: normal;
    font-size: 2vh;
    color: rgb(133, 133, 133);
    margin-bottom: 0;
  }

  &__role {
    color: rgb(95, 95, 95);
    font-weight: bold;
    margin-top: 0.6vh;
    font-size: 1.6vh;
  }
}
